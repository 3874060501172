.contacts .icon-sons {
  padding: 0px 0 0 1px;
  font-size: 13px;
}

.contacts .icon-sons-background {
  width: 22px;
  height: 22px;
  line-height: 23px;
}

.contacts.contacts-label {
  font-size: 20px;
  margin-left: 25px;
  color: #6a6a6a;
}

.contacts .contacts-margin-bottom{
  margin-bottom: 12px;

}

.social-links div{
  display: inline-block;
}
.social-links .social-links-text{
  color: $brand-primary;
  font-size: 20px;
  font-weight: 300;
  line-height: 61px;
  margin-right: 20px;
}
.social-links .social-links-icon{
  font-size: 36px;
  color: #6a6a6a;
  display: inline-block;
  margin-right: 15px;
  &:hover {
    text-decoration: none;
    color: $brand-primary;
  }
}

textarea.form-control {
  min-height: 84px;
}