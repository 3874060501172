textarea {
  max-width: 100%;
}

.form-group {
  @include placeholder-style {
    font-weight: 300;
    font-style: italic;
  }
}

.form-group .error {
  color: $alert-danger-text;
  margin-top: 5px;
}

.contact-form .form-control {
  border: none;
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.15);
  border-radius: 3px;
}

.contact-form .contact-form-submit {
  text-align: right;
  line-height: 65px;
}

.contact-form .btn-lg {
  font-size: 13px;
  padding: 13px 30px;
}
.contact-form .btn.disabled {
  opacity: 0.2;
}
@media (max-width: 480px) {
  .contact-form .contact-form-recaptcha {
    width: 100%;
  }
  .contact-form .contact-form-recaptcha .g-recaptcha > div {
    width: 100% !important;
    text-align: center;
  }

  .contact-form .contact-form-submit {
    text-align: center;
    width: 100%;
  }
}

.contact-form .form-control {
  padding: 6px 12px 6px 25px;
}