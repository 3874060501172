
.section-try-now {
  background-image: url("/images/1-1.jpg");
  min-height: 100%;
  background-size: cover;
  background-position: center;
}

.section-try-now .container {
  padding-top: 100px;
  padding-bottom: 40px;
}

$slider-text-highlight-color: #eaeaea;
$slider-text-highlight-padding: 20px;
.text-highlighted {
  border-left: 20px solid $slider-text-highlight-color;
  line-height: 1;
  padding: 3px 0;
  border-left: $slider-text-highlight-padding * 2 solid $slider-text-highlight-color;
  margin-bottom: 20px;
}

.text-highlighted h1,
.text-highlighted h2 {
  font-size: 48px;
  font-weight: 700;
  background-color: $slider-text-highlight-color;
  padding: 4px 0;
  color: $brand-primary;
  display: inline;
  margin: 0;
  border-color: $slider-text-highlight-color;
  line-height: 1.4;
}
.text-highlighted h2 {
  line-height: 1.46;
}

.text-highlighted h1 > span,
.text-highlighted h2 > span {
  position: relative;
  left: $slider-text-highlight-padding * -1;
}

.section-try-now h2 {
  font-size: 24px;
}

.section-try-now .form-control {
  height: 35px;
  margin-bottom: 14px;
  border-radius: 3px;
  background-color: #eaeaea;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3);
  font-size: 16px;
}

.form-control::-webkit-input-placeholder {
  color: #606060;
}

.try-now-form {
  margin-top: 30px;
}

.section-try-now .btn-primary {
  border-radius: 3px;
}

.section-try-now .form-bottom-text {
  color: #fff;
  font-weight: 700;
  margin-bottom: 14px;
  margin-top: 4px;
}

