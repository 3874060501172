$icon-sons-company: "\e900";
$icon-sons-location:"\e902";
$icon-sons-phone:"\e911";
$icon-sons-envelope:"\e905";
$icon-sons-devices: "\e904";
$icon-sons-no-hardware:"\e905";
$icon-sons-checklist:"\e906";
$icon-sons-settings:"\e907";
$icon-sons-realtime:"\e908";
$icon-sons-power:"\e909";
$icon-sons-arrow-left:"\e901";
$icon-sons-arrow-right:"\e904";
$icon-sons-facebook:"\e90c";
$icon-sons-linkedin:"\e90d";
$icon-sons-twitter:"\e90e";
$icon-sons-play:"\e903";
@font-face {
  font-family: 'sons-web';
  src: url($icon-font-path + 'sons/sons-web.eot?v2m02m');
  src: url($icon-font-path + 'sons/sons-web.eot?v2m02m#iefix') format('embedded-opentype'),
  url($icon-font-path + 'sons/sons-web.ttf?v2m02m') format('truetype'),
  url($icon-font-path + 'sons/sons-web.woff?v2m02m') format('woff'),
  url($icon-font-path + 'sons/sons-web.svg?v2m02m#sons-web') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-sons-background{
  background-color: $brand-primary;
  color: $white;
  width: 22px;
  height: 22px;
  line-height: 22px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
}

.icon-sons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'sons-web' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-company:before {
  content: $icon-sons-company;
}

.icon-location:before {
  content: $icon-sons-location;
}

.icon-phone:before {
  content: $icon-sons-phone;
}

.icon-envelope:before {
  content: $icon-sons-envelope;
}

.icon-devices:before {
  content: $icon-sons-devices;
}

.icon-no-hardware:before {
  content: $icon-sons-no-hardware;
}

.icon-checklist:before {
  content: $icon-sons-checklist;
}

.icon-settings:before {
  content: $icon-sons-settings
}

.icon-realtime:before {
  content: $icon-sons-realtime;
}

.icon-power:before {
  content: $icon-sons-power;
}

.icon-arrow-left:before {
  content: $icon-sons-arrow-left;
}

.icon-arrow-right:before {
  content: $icon-sons-arrow-right;
}

.icon-facebook:before {
  content: $icon-sons-facebook;
}

.icon-linkedin:before {
  content: $icon-sons-linkedin;
}

.icon-twitter:before {
  content: $icon-sons-twitter;
}
.icon-play:before {
  content: $icon-sons-play;
}