.slider-section,
.slick-slider,
.slick-list,
.slick-track,
.slick-slide {
  height: 100%;
}

.inner-slider-section {
  position: relative;
  height: 100%;
}

@media(min-width: $screen-phone) {
  .inner-slider-section {
    height: auto;
    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide {
      height: auto;
      min-height: 325px;
    }
  }
}

.slick-slider {
  position: relative;
}

.slick-slide {
  background-size: cover;
  background-position: center;
  position: relative;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  margin: auto 0;
  z-index: 1000;
  color: $white;
  font-size: $slider-arrow-size;
  line-height: $slider-arrow-size;
  cursor: pointer;
  border-radius: 50%;
  text-shadow:
  -2px -2px 1px rgba(#000, 0.1),
  2px -2px 1px rgba(#000, 0.1),
  -2px 2px 1px rgba(#000, 0.1),
  2px 2px 1px rgba(#000, 0.1);
  @include opacity(1);
  &:hover {
    text-shadow: none;
    @include opacity(0.7);
    background-color: $brand-primary;
  }
}

.slick-arrow.slick-prev {
  left: $slider-arrow-distance-from-side;
  padding: 7px 18px 7px 12px;
}

.slick-arrow.slick-next {
  right: $slider-arrow-distance-from-side;
  padding: 7px 12px 7px 18px;
}

ul.slick-dots {
  padding: 0;
  list-style-type: none;
  position: absolute;
  bottom: 30px;
  text-align: center;
  width: 100%;
  z-index: 10;
}

ul.slick-dots li {
  display: inline-block;
}

ul.slick-dots li {
  > button {
    background-color: rgba(#b9b2ac, 0.5);
    font-size: 0;
    line-height: 0;
    display: block;
    width: 8px;
    height: 8px;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    border-radius: 50%;
    margin: 0 3px;
  }
}

ul.slick-dots li.slick-active {
  > button {
    background-color: rgba($brand-primary, 0.5);
  }
}

.slider-content-wrap {
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;
}

.slide {
  display: table !important;
}

.slider-content {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  color: $brand-primary;
}

.slider-content .text {
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 5%;
}

$slider-text-highlight-color: #eeeeed;
$slider-text-highlight-padding: 20px;
.slider-content .slider-text-highlighted {
  margin-bottom: 20px;

  span {
    display: inline-block;
    padding: $slider-text-highlight-padding/2 $slider-text-highlight-padding;
    background-color: rgba(255,255,255,.7);

    @media (max-width: $screen-sm-max) {
      padding: $slider-text-highlight-padding/4 $slider-text-highlight-padding/2;
    }
  }
}

.slider-content .slider-text-highlighted h1,
.slider-content .slider-text-highlighted h2 {
  font-size: 48px;
  line-height: 50px;
  font-weight: 700;
  padding: 0;
  color: $brand-primary;
  margin: 0;
}

.slider-content .slider-text-highlighted h1 {
  font-size: 60px;
  line-height: 62px;
}

.slider-content .slider-text-highlighted h2 {
  font-size: 24px;
  line-height: 26px;
  color: #6a6a6a;
}

.slider-content .form-group {
  margin-left: 5%;
}

.slider-content .form-group input {
  font-size: 16px;
  font-style: italic;
  background-color: rgba(#fff, 0.7);
  color: #3c3c3c;
  display: inline-block;
  min-width: 270px;
  margin-right: 20px;
  &:focus {
    border-color: #fff;
    box-shadow: none;
    outline: 0;
  }
  &::-webkit-input-placeholder {
    color: #3c3c3c !important;
  }

  &:-moz-placeholder { /* Firefox 18- */
    color: #3c3c3c !important;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    color: #3c3c3c !important;
  }

  &:-ms-input-placeholder {
    color: #3c3c3c !important;
  }
}

.slider-content .center-block {
  float: none;
}

@media(max-width: $grid-float-breakpoint) {
  .slider-content .text {
    font-size: 2.033em;
  }
}

@media (max-width: $screen-sm) {
  .slider-content .slider-text-highlighted {
    line-height: 0.4;
  }
  .slider-content .slider-text-highlighted h1 {
    font-size: 20px;
    line-height: 1.44;
  }

  .slider-content .slider-text-highlighted h2 {
    font-size: 12px;
  }

  .slider-section .form-control {
    width: 64%;
  }
  .slider-section .form-group input {
    min-width: initial;
  }
}
