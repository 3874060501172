//
// Loaders
// --------------------------------------------------

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  border-top: 0.2em solid rgba($white, 0.2);
  border-right: 0.2em solid rgba($white, 0.2);
  border-bottom: 0.2em solid rgba($white, 0.2);
  border-left: 0.2em solid $white;
  transform: translateZ(0);
  animation: rotate 1.2s infinite cubic-bezier(0.5, -0.5, 0.5, 1.5);

  &,
  &:after {
    border-radius: 50%;
    width: $line-height-base + em;
    height: $line-height-base + em;
  }
}

.spinner-primary {
  border-top: 0.2em solid rgba($brand-primary, 0.2);
  border-right: 0.2em solid rgba($brand-primary, 0.2);
  border-bottom: 0.2em solid rgba($brand-primary, 0.2);
  border-left: 0.2em solid $brand-primary;
}

.spinner-block {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.body-spinner {
  opacity: 0;
  transition: opacity .3s ease-in-out;

  &::before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-body-spinner;
    background: rgba(255, 255, 255, 0.8);
    content: '';
  }

  .spinner-block {
    z-index: $zindex-body-spinner;
  }
}

.loading {
  user-select: none;

  .body-spinner {
    opacity: 1;
  }
}

body.loading {
  overflow: hidden;
}

.spinner-lg {
  font-size: $font-size-h1;
}
