html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn:focus, .btn:active:focus, .btn.active:focus,
.btn.focus, .btn:active.focus, .btn.active.focus,
a:focus, a:active:focus, a.active:focus,
a.focus, a:active.focus, a.active.focus,
li:focus, li:active:focus, li.active:focus,
li.focus, li:active.focus, li.active.focus {
  outline: none;
}

.row-no-padding {
  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media(min-width: $grid-float-breakpoint) {
  .vertical-align {
    display: table;
    table-layout: fixed;
    height: 100%;
  }

  .vertical-align > .vertical-align-center {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }

  .vertical-align > .vertical-align-bottom {
    display: table-cell;
    vertical-align: bottom;
    float: none;
  }

  .vertical-align-center.full-width,
  .vertical-align-bottom.full-width {
    width: 100%;
  }
}

.text-bold {
  font-weight: 700;
}

.section-no-padding-bottom {
  padding-bottom: 0;
}

.img-responsive.full-width {
  width: 100%;
}

.privacy-header-container,
.terms-header-container {
  margin-top: 100px;
}

#privacy-section,
#terms-section {
  padding-bottom: 30px;

  nav {
    width: 100%;
    border: 1px solid #455a64;
    background-color: #ffffff;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 3px;

    @media (min-width: 768px) {
      float: left;
      width: 360px;
      margin: 10px 40px 20px 0;
    }

    h4 {
      font-size: 18px;
      font-weight: bold;
      color: #455a64;
      text-transform: uppercase;
      margin: 0 0 10px 0;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        list-style-type: none;
        padding: 2px 0;

        a {
          font-weight: bold;
        }
      }
    }

    li,
    p {
      font-size: 16px;
    }
  }
}

@media screen and (max-height: 575px) {
  #rc-imageselect, .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}