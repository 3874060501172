.navbar {
  border-bottom: 1px solid #d0d0d0;
  background-color: #fff;
  transition: padding 0.2s ease-in-out;
  margin-top: 0;
}

@media (max-width: $screen-sm-max) {
  .navbar-fixed-bottom .navbar-collapse,
  .navbar-fixed-top .navbar-collapse,
  .pre-scrollable {
    max-height: 440px;
  }

  .animate-background {
    transition: all 0.2s ease-in-out;
  }
}

.navbar.navbar-transparent {
  padding-top: 20px;
  background-color: transparent;
  border: none;
}

.navbar-nav {
  > li {
    > a {
      font-weight: 700;
      text-transform: uppercase;
      color: #353434;
      font-size: 13px;
      &.with-btn {
        padding-top: (($navbar-height - $line-height-computed) / 2) - 6;
        padding-bottom: (($navbar-height - $line-height-computed) / 2) - 7;
      }
      &:hover {
        color: $brand-primary;
      }
    }
    &.active {
      > a {
        color: $brand-primary;
      }
    }
  }
}

.navbar .container{
  position: relative;
}

.navbar-brand {
  transition: opacity 0.3s ease-in-out;
  padding: 8px $navbar-padding-horizontal;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  img {
    height: 60px;
  }
}

.navbar-toggle {
  border-color: #353434;
  .icon-bar {
    background-color: #353434;
  }
}

.navbar-default {
  .navbar-nav {
    > li,
    > .active {
      > a {
        &:hover,
        &:focus {
          @media (max-width: $grid-float-breakpoint-max) {
            background-color: $navbar-default-toggle-hover-bg;
          }
        }
      }
    }

    > .active {
      > a {
        @media (max-width: $grid-float-breakpoint-max) {
          background-color: $navbar-default-toggle-hover-bg;

        }
      }
    }
  }
}

.navbar li a{
  transition: all 0.3s ease-in-out;
}

@media (max-width: $grid-float-breakpoint-max) {
  .navbar.navbar-transparent {
    padding-top: 0;
  }
}

.navbar-brand.white-logo {
    opacity: 0;
}

.navbar-white {
  .navbar-brand.dark-logo {
    opacity: 0;
  }
  .navbar-brand.white-logo {
    opacity: 1;
  }
  .navbar-nav li a {
    color: #fff;
  }
}


