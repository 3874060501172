.scheme {
  position: relative;
}

.scheme .scheme-text {
  font-size: 24px;
  font-weight: 700;
  color: #403f3f;
}

.about-scheme .about-scheme-top-text {
  position: absolute;
  left: 15%;
  top: 0;
}

.about-scheme .about-scheme-bottom-text {
  position: absolute;
  left: 15%;
  bottom: 34%;
}

.about-scheme .scheme-image {
  padding-top: 40px;
}

.scheme-how-it-works-full {
  padding-top: 60px;
}

.scheme-how-it-works-full .scheme-text {
  font-size: 15px;
  width: 200px;
  display: inline-block;
  position: absolute;
  top: 0;
  text-align: center;
}

.scheme-how-it-works-full .scheme-text.about-scheme-connect-text {
  left: 8%;
}

.scheme-how-it-works-full .scheme-text.about-scheme-upload-text {
  left: 30%;
}

.scheme-how-it-works-full .scheme-text.about-scheme-schedule-text {
  left: 57%;
}

.scheme-how-it-works-full .scheme-text.about-scheme-monitor-text {
  left: 84%;
}

.scheme-how-it-works .scheme-text {
  font-size: 15px;
  text-align: center;
}

.scheme-how-it-works .img-responsive{
  margin: 20px auto;
}

@media(min-width: $grid-float-breakpoint) {
  .scheme-how-it-works {
    height: 280px;
  }

  .scheme-how-it-works .img-responsive {
    margin: 0 auto;
    position: absolute;
    display: inline-block;
    left: 50%;
    top: 50%;
    font-size: 7em;
    transform: translate(-50%, -50%);
  }
}

@media(max-width: $grid-float-breakpoint) {
  .scheme-col {
    &:first-child .scheme-text{
      margin-top: 0;
    }
    .scheme-text {
      margin-top: 40px;
    }
  }
}

h2.section-header.scheme-bottom-header {
  margin-top: 40px;
  text-align: center;
  margin-bottom: 0;
}
