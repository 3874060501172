.footer {
  background-color: $footer-bg;
  color: $footer-color;
  min-height: $footer-height;
  font-size: $font-size-xsmall;
  font-weight: 400;
  padding-top: 30px;
  padding-bottom: 30px;
}

.footer a {
  color: $footer-color;
}

.footer p {
  margin: 0;
}