.section {
  position: relative;
  height: auto;
  min-height: 80%;
}

section.no-full-height {
  min-height: initial;
}

.section-padding {
  padding-bottom: 100px;
}

.section-no-padding-bottom {
  padding-bottom: 0;
}

.section-gray {
  background-color: $gray-lighter;
}

.section-wrapper {
  min-height: 100%;
}
.section-image {
  margin: 20px 0;
}
.section-image img {
  height: auto;
  display: block;
  margin: 0 auto;
}

.section h1.section-header {
  color: $brand-primary;
  margin-bottom: 35px;
}

.section h2.section-header {
  color: $gray-darker;
  margin: 0;
  margin-bottom: 30px;
}

.section-video {
  position: relative;
  display: block;
  color: $white;
}

.section-video .section-video-play {
  position: absolute;
  display: inline-block;
  left: 50%;
  top: 50%;
  font-size: 7em;
  transform: translate(-50%, -50%);
  transition: all 0.15s ease-in-out;
  text-shadow: 0 0 24px rgba(0, 0, 0, 1);
  @include opacity(0.7);
}

.section-video:hover {
  color: $white;
}

.section-video:hover .section-video-play {
  transform: translate(-50%, -50%) scale(1.2);
  @include opacity(1);
}

.section-list {
  list-style-type: none;
  padding-left: 0;
}

.section-list li {
  margin-bottom: 20px;
}

.section-list-number {
  border-radius: 50%;
  background-color: $brand-primary;
  color: $white;
  height: 33px;
  width: 33px;
  line-height: 33px;
  margin-right: 15px;
  text-align: center;
  display: inline-block;
  font-size: 18px;
}

@media(max-width: $screen-phone) {
  .section {
    min-height: initial;
  }
  .section-padding {
    padding-top: 10px;
    padding-bottom: 30px;
  }
}

@media (min-width: 770px) {
  .features-section {
    background-image: url('/images/feutures-bg.jpg');
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 770px) {
  .features-section {
    padding-bottom: 0;
  }
}

.how-it-works-section h2.section-header,
.features-section h2.section-header {
  margin-top: 40px;
  color: #403f3f;
}

.features-section strong {
  color: #403f3f;
}