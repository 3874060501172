.section-cases {
  background: url("/images/bussiness-case-pattern.jpg");
}

.section-cases h1.section-header,
.section-cases h2.section-header {
  color: #fff;
}
.section-cases h2.section-header {
  margin-bottom: 70px;
}
.case-item {
  margin: 1px;
  position: relative;
  display: block;
}

.section-cases .case-item {
  margin-bottom: 30px;

  .title {
    margin-top: 2px;
  }

  @media(min-width: $screen-sm-min) {
    margin-bottom: 60px;

    .title {
      margin-top: 30px;
    }
  }
}

.case-item .title {
  color: #fff;
  text-align: center;
  font-weight: 700;
  margin-top: 30px;
}

.case-item img {
  margin: 0 auto;
}

.case-item.full-width img {
  width: 100%;
}

.case-section {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media(max-width: $screen-sm) {
  .real-case .col-xs-12 {
    > .case-item {
      margin-bottom: 10px;
    }

    &:last-child > .case-item {
      margin-bottom: 0;
    }
  }
}